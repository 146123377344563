import React, {useEffect, useState} from 'react';
import FilerobotImageEditor from 'filerobot-image-editor';
import './App.css'

function App() {
    const [src, setSrc] = useState('');
    const [show, toggle] = useState(false);

    const setImg = (e) => {
        const img = URL.createObjectURL(e.target.files[0])
        setSrc(img);
        toggle(true);
    }

    useEffect(() => {
        window.document.querySelectorAll(".img-load").forEach(el =>{
            if (el.complete) {
                el.classList.remove('opacity-0')
            } else {
                el.addEventListener('load', () => {
                    el.classList.remove('opacity-0')
                })
            }
        })
    }, [])

    return (
        <div className="bg-pattern">
            <div className="flex flex-col h-[100vh]">
                <div className="w-full bg-[#263138] py-6 border-b-2 border-gray-600">
                    <div className="container">
                        <a href="/"><img className="max-w-[100px]" src={require('./assets/images/picart.png')} alt=""/></a>
                    </div>
                </div>
                <div className="w-full container flex flex-col lg:flex-row grow">
                    <div className="w-full lg:w-1/2 flex gap-2 flex-col justify-center">
                        <div className="flex gap-2">
                            <div className="w-1/2 h-[15vh] md:h-[35vh] bg-cover bg-center bg-[url('https://images.unsplash.com/photo-1685691407128-3642004de6e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=20&q=80')]">
                                <img className="img-load opacity-0 duration-200 w-full h-full object-cover" src="https://images.unsplash.com/photo-1685691407128-3642004de6e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80" alt=""/>
                            </div>
                            <div className="w-1/2 h-[15vh] md:h-[35vh] bg-cover bg-center bg-[url('https://images.unsplash.com/photo-1686070166401-bdf39d2d774b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=20&q=80')]">
                                <img className="img-load opacity-0 duration-200 w-full h-full object-cover" src="https://images.unsplash.com/photo-1686070166401-bdf39d2d774b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80" alt=""/>
                            </div>
                        </div>
                        <div className="w-full h-[20vh] md:h-[35vh] bg-cover bg-center bg-[url('https://images.unsplash.com/photo-1685470934582-3636319a3be9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=20&q=80')]">
                            <img className="img-load opacity-0 duration-200 w-full h-full object-cover" src="https://images.unsplash.com/photo-1685470934582-3636319a3be9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80" alt=""/>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex flex-col p-6 justify-center">
                        <h2 className="text-4xl font-bold text-white">
                            Welcome to Picraft Photo Editor
                        </h2>
                        <p className="mt-5 text-lg text-white">
                            Please upload an image to proceed!
                        </p>
                        <div className="mt-10">
                            <input type="file" id="actual-btn" hidden accept="image/*" onChange={setImg}/>
                            <label htmlFor="actual-btn" className="p-4 bg-sky-300 hover:bg-sky-400 font-bold rounded-md cursor-pointer">Upload an Image</label>
                        </div>
                    </div>
                </div>

                <FilerobotImageEditor
                    show={show}
                    src={src}
                    onClose={() => { toggle(false) }}
                />
            </div>
        </div>
    );
}

export default App;
